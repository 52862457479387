<template>
  <div class="container">
    <div class="w-full px-12 border border-gray-500 rounded-md">
      <h1 class="border-b font-bold mt-8">Gestione Profilo</h1>
      <table class="w-full my-8 text-gray-500">
        <tr>
          <td class="uppercase">Nome</td>
          <td>{{ currentUserData.attributes.name }}</td>
        </tr>
        <tr>
          <td class="uppercase">Cognome</td>
          <td>{{ currentUserData.attributes.family_name }}</td>
        </tr>
        <tr>
          <td class="uppercase">EMAIL</td>
          <td>{{ currentUserData.attributes.email }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("auth", ["currentUserData"])
  }
};
</script>

<style lang="postcss">
td:last-child {
  @apply px-3;
}
td:first-child {
  width: 1%;
  white-space: nowrap;
}
</style>
